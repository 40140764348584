import { Button } from "@mui/material";
import QRCode from "qrcode.react";
import classes from "./style.module.scss";

const qrCodeCanvasId = "qrCode";
const qrCodeFilename = "qr-code";

export default function Form({ value }: { value: string }) {
  function handleDownload() {
    const canvas: any = document.getElementById(qrCodeCanvasId);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrCodeFilename}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <>
      {value && (
        <div className={classes.wrapper}>
          <QRCode id={qrCodeCanvasId} value={value} />
          <Button onClick={handleDownload}>Download QR code</Button>
        </div>
      )}
    </>
  );
}
