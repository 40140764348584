import classes from "./App.module.scss";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  return (
    <div className={classes.App}>
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
