import { Link } from "@mui/material";
import classes from "./style.module.scss";

export default function Footer() {
  return (
    <footer className={classes.wrapper}>
      <p>
        Service developped by Rael CALITRO,{" "}
        <span>
          <Link href="https://rael-calitro.ovh">My website</Link>
        </span>
      </p>
    </footer>
  );
}
