import React from "react";
import Form from "./components/Form";
import QrCode from "./components/QrCode";
import classes from "./style.module.scss";

export default function Home() {
  const [value, setValue] = React.useState("");
  function generateQRCode(value: string) {
    setValue(value);
  }

  return (
    <div className={classes.wrapper}>
      <Form generateQRCode={generateQRCode} />
      <div className={classes.qrCode}>
        <QrCode value={value} />
      </div>
    </div>
  );
}
