import { Button, TextField } from "@mui/material";
import React from "react";
import classes from "./style.module.scss";

export default function Form({
  generateQRCode,
}: {
  generateQRCode: (value: string) => void;
}) {
  const [text, setText] = React.useState("");

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setText(event.target.value);
  }

  function handleConfirm() {
    generateQRCode(text);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      handleConfirm();
    }
  }

  return (
    <div className={classes.wrapper}>
      <TextField
        placeholder="Enter the text to encode"
        label="text to encode"
        name="text"
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Button onClick={handleConfirm}>Generate QR code</Button>
    </div>
  );
}
